exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-academy-index-tsx": () => import("./../../../src/pages/academy/index.tsx" /* webpackChunkName: "component---src-pages-academy-index-tsx" */),
  "component---src-pages-academy-intro-tsx": () => import("./../../../src/pages/academy/Intro.tsx" /* webpackChunkName: "component---src-pages-academy-intro-tsx" */),
  "component---src-pages-academy-know-more-tsx": () => import("./../../../src/pages/academy/KnowMore.tsx" /* webpackChunkName: "component---src-pages-academy-know-more-tsx" */),
  "component---src-pages-academy-pricing-tsx": () => import("./../../../src/pages/academy/Pricing.tsx" /* webpackChunkName: "component---src-pages-academy-pricing-tsx" */),
  "component---src-pages-association-index-tsx": () => import("./../../../src/pages/association/index.tsx" /* webpackChunkName: "component---src-pages-association-index-tsx" */),
  "component---src-pages-association-intro-tsx": () => import("./../../../src/pages/association/Intro.tsx" /* webpackChunkName: "component---src-pages-association-intro-tsx" */),
  "component---src-pages-association-know-more-tsx": () => import("./../../../src/pages/association/KnowMore.tsx" /* webpackChunkName: "component---src-pages-association-know-more-tsx" */),
  "component---src-pages-association-pricing-tsx": () => import("./../../../src/pages/association/Pricing.tsx" /* webpackChunkName: "component---src-pages-association-pricing-tsx" */),
  "component---src-pages-athlete-index-tsx": () => import("./../../../src/pages/athlete/index.tsx" /* webpackChunkName: "component---src-pages-athlete-index-tsx" */),
  "component---src-pages-athlete-intro-tsx": () => import("./../../../src/pages/athlete/Intro.tsx" /* webpackChunkName: "component---src-pages-athlete-intro-tsx" */),
  "component---src-pages-athlete-know-more-tsx": () => import("./../../../src/pages/athlete/KnowMore.tsx" /* webpackChunkName: "component---src-pages-athlete-know-more-tsx" */),
  "component---src-pages-athlete-pricing-tsx": () => import("./../../../src/pages/athlete/Pricing.tsx" /* webpackChunkName: "component---src-pages-athlete-pricing-tsx" */),
  "component---src-pages-brand-index-tsx": () => import("./../../../src/pages/brand/index.tsx" /* webpackChunkName: "component---src-pages-brand-index-tsx" */),
  "component---src-pages-brand-intro-tsx": () => import("./../../../src/pages/brand/Intro.tsx" /* webpackChunkName: "component---src-pages-brand-intro-tsx" */),
  "component---src-pages-brand-know-more-tsx": () => import("./../../../src/pages/brand/KnowMore.tsx" /* webpackChunkName: "component---src-pages-brand-know-more-tsx" */),
  "component---src-pages-brand-pricing-tsx": () => import("./../../../src/pages/brand/Pricing.tsx" /* webpackChunkName: "component---src-pages-brand-pricing-tsx" */),
  "component---src-pages-cta-tsx": () => import("./../../../src/pages/CTA.tsx" /* webpackChunkName: "component---src-pages-cta-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intro-tsx": () => import("./../../../src/pages/Intro.tsx" /* webpackChunkName: "component---src-pages-intro-tsx" */),
  "component---src-pages-know-more-tsx": () => import("./../../../src/pages/KnowMore.tsx" /* webpackChunkName: "component---src-pages-know-more-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-tournament-index-tsx": () => import("./../../../src/pages/tournament/index.tsx" /* webpackChunkName: "component---src-pages-tournament-index-tsx" */),
  "component---src-pages-tournament-intro-tsx": () => import("./../../../src/pages/tournament/Intro.tsx" /* webpackChunkName: "component---src-pages-tournament-intro-tsx" */),
  "component---src-pages-tournament-know-more-tsx": () => import("./../../../src/pages/tournament/KnowMore.tsx" /* webpackChunkName: "component---src-pages-tournament-know-more-tsx" */),
  "component---src-pages-tournament-pricing-tsx": () => import("./../../../src/pages/tournament/Pricing.tsx" /* webpackChunkName: "component---src-pages-tournament-pricing-tsx" */)
}

